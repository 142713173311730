import React from 'react';
import SEO from '../components/seo';
import Layout from '../components/Layout';
import Lore from '../components/Lore/Lore';

const LorePage = () => {
  return (
    <Layout>
      <SEO
        title="Lore"
        keywords={[`thecodesmith`, `lore`, 'devgnar', `keyolnir`]}
      />
      <Lore></Lore>
    </Layout>
  );
};

export default LorePage;
