import React from 'react';
import styled from 'styled-components';
import ColoredLine from '../utils/ColoredLine';

const Lore = ({ className }) => {
  return (
    <section className={className}>
      <div className="loreHeading">
        <h2>About Lore</h2>
        <ColoredLine width={'50%'} />
      </div>
      <article className="loreContent aboutContent">
        <p>
          TheCodesmith lore is a creative experiment to test if the creation of
          fictional characters and settings can be used to enhance the
          experience of visiting and learning from the website's resources.
        </p>
        <p>
          The main character is a dwarf (because who wants to read about humans)
          named Devgnar Lorem (pronounced /devnär/). Devgnar is skilled in the
          art of codesmithing, which is what software development would be if
          the mental aspect was instead physical and a touch of magic was added
          to the whole process.
        </p>

        <p>
          In Devgnar's universe there are no computers to program. Instead code,
          the raw material for codesmithing, is a physical resource, similar to
          metal, which must be mined from the earth. Once smelted, and made into
          bars, the code is ready to be shaped into runes which for reasons only
          fully understood by the codesmiths are able to have physical effects
          on the world around them.
        </p>
      </article>

      <div className="loreHeading">
        <h2>The Tales of Devgnar</h2>
        <ColoredLine width={'50%'} />
      </div>
      <article className="loreContent">
        <p>
          Here you will find stories of the brave dwarf, Devgnar Lorem, artisan
          codesmith, and wielder of the legendary hammer Keyolnir. It is said
          that Keyolnir is the strongest of all hammers, and its mystic runes
          glow orange when bugs are nearby.
        </p>
        <p>
          Revered in his home city of Codeforge, and well known in the
          surrounding lands it is often forgotten that Devgnar wasn't always the
          experienced, and respected craftsman he has grown to be. Long ago, and
          years before the claiming of Keyolnir, Devgnar labored to learn the
          smithing craft.
        </p>
        <p>
          <strong>Lore In Progress, check back for future updates</strong>
        </p>
      </article>
    </section>
  );
};

export default styled(Lore)`
  background: var(--mainEarth);
  min-height: 20em;
  padding: 2rem 0;
  color: white;
  text-align: center;

  .loreHeading,
  .loreContent {
    background: var(--darkEarth);
    width: 90vw;
    margin: 0 auto;
  }

  .loreHeading {
    padding: 2rem 0 0.5rem 0;
  }

  .loreContent p {
    font-size: 1.25rem;
    width: 85%;
    margin: 0 auto;
    line-height: 2.2rem;
    letter-spacing: 0.02rem;
    padding-bottom: 2rem;
  }

  .aboutContent {
    margin-bottom: 2rem;
  }

  @media (min-width: 576px) {
    .loreHeading,
    .loreContent {
      width: 80vw;
    }
  }

  @media (min-width: 768px) {
    .loreHeading,
    .loreContent {
      width: 70vw;
    }
  }

  @media (min-width: 992px) {
    .loreHeading,
    .loreContent {
      width: 55vw;
    }
  }
`;
